import Input from "../../../components/inputs/reusable_input";
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { authResetPassword } from "../../../app/api/auth";
import Loading from "../../../components/loading";
import { useUserAuth } from "../../../app/hooks";

interface ResetPasswordFormState {
  email: string;
  uuid: string;
  newPassword: string;
  repeatNewPassword: string;
}

const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [formState, setFormState] = useState<ResetPasswordFormState>({
    email: '',
    uuid: searchParams.get('retok')??'',
    newPassword: '',
    repeatNewPassword: ''
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { isUserLogged } = useUserAuth();

  useEffect(() => { 
    if (isUserLogged) navigate("/");
  } , [isUserLogged]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      formState.email === '' ||
      formState.uuid === '' ||
      formState.newPassword === '' ||
      formState.repeatNewPassword === ''
    ) return;
    if (formState.newPassword !== formState.repeatNewPassword) {
      toast("Las contraseñas no coinciden", {icon: '🚫'});
      return;
    }
    setLoading(true);
    authResetPassword({
      email: formState.email,
      uuid: formState.uuid,
      password: formState.newPassword,
      "repeat_password": formState.repeatNewPassword
    }).then((response) => {
      if (response.success === false) {
        setLoading(false);
        if (response.messages && response.messages?.length > 0)
          toast(response.messages[0], {icon: '🚫'});
        return;
      }
      setLoading(false);
      navigate("/");
      toast("Contraseña cambiada con éxito", {icon: '👍'});
    });
  };

  return (
    <div>
      <h3 className="mb-5 text-center">Cambiar contraseña</h3>
      { loading ? <Loading /> :
        <form onSubmit={handleSubmit} className="main-form">
          <div className="mb-3">
            <Input
              label="Email:"
              name="email"
              type="email"
              id="email"
              value={formState.email}
              onChange={handleInputChange}            
              required
            />
            <Input
              label="Nueva contraseña:"
              name="newPassword"
              type="password"
              id="newPassword"
              value={formState.newPassword}
              onChange={handleInputChange}            
              required
            />
            <Input
              label="Repite la contraseña:"
              name="repeatNewPassword"
              type="password"
              id="repeatNewPassword"
              value={formState.repeatNewPassword}
              onChange={handleInputChange}            
              required
            />
          </div>
          <div className="d-flex justify-content-center" >
            <button type="submit" className="btn btn-primary mt-3 mb-3">
              Continuar
            </button>
          </div>
        </form>
      }
    </div>
  );
};

export default ResetPassword;
