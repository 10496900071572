// @flow
export const EVENT_BUS_AUTH_USER_LOGOUT = "auth_user_logout_finished";

export const EVENT_BUS_GENERAL_ERROR_UNAUTHORIZED = "general_error_unauthorized";
export const EVENT_BUS_GENERAL_ERROR_FORBIDDEN = "general_error_forbidden";

export interface BusEventDataDetail<T = unknown> {
    error: boolean;
    errorMessages?: string[];
    data? : T;
}

export type UnregisterCallback = () => void;

const eventBus = {
    on<T = unknown>(event: string, callback: (e: BusEventDataDetail<T>) => void) : UnregisterCallback {
        const mycallback = (e : Event) => callback((e as CustomEvent).detail);

        document.addEventListener(event, mycallback);

        const unreg : UnregisterCallback = () => document.removeEventListener(event, mycallback);

        return unreg;
    },
    dispatch<T = unknown>(event: string, data?: BusEventDataDetail<T>) : void {
        document.dispatchEvent(new CustomEvent<BusEventDataDetail>(event, { detail: data }));
    },

};

export default eventBus;
