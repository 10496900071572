import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UserDocument, uploadDocument } from "../../../app/api/documents";
import toast from "react-hot-toast";
import Loading from "../../../components/loading";
import Thumbnail from "../../../components/documents/thumbnail";
import CustomModal from "../../../components/customModal";

type Props = {
  documents: UserDocument[];
  setDocuments: Dispatch<SetStateAction<UserDocument[]>>;
  selectedDocument: UserDocument | null;
  setSelectedDocument: Dispatch<SetStateAction<UserDocument | null>>;
};

const FileExplorer: React.FC<Props> = ({ documents, setDocuments, selectedDocument, setSelectedDocument }: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (files && files[0]) {
      setLoading(true);
      const formData = new FormData();
      formData.append("data_file", files[0]);
      uploadDocument(formData).then((response) => {
        if (response.success === false) {
          setLoading(false);
          if (response.messages && response.messages?.length > 0)
            toast(response.messages[0], { icon: '🚫' });
          return;
        }
        toast("Archivo subido correctamente", { icon: '👍' });
        if (response.data) {
          setDocuments([{ ...response.data }, ...documents]);
        }
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    setSelectedDocument(documents[0]);
    if (documents.length === 0) setShowModal(true);
    else setShowModal(false);
  }, [documents]);

  return (<>
    <div className="col-3 bg-second m-0 p-0">
      <div className="bg-main d-flex align-items-center border-bottom justify-content-between p-4" style={{ height: "var(--navbar-height)" }}>
        <h5>Documentos guardados:</h5>
        {loading ? <Loading width={38} /> :
          <div>
            <input id="file-input" name="file-input" onChange={onFileChange} type="file" accept="application/pdf" hidden />
            <label
              htmlFor="file-input"
              className="btn btn-primary fw-bold"
              style={{ borderRadius: '50%' }}
              title="Subir documento"
            >+</label>
          </div>
        }
      </div>
      <div className="m-0 p-0 pb-3" style={{ height: 'calc(100vh - (var(--navbar-height) * 2))', overflowY: 'auto' }}>
        {documents.map((document, index) => {
          return <Thumbnail
            key={index}
            document={document}
            onClick={() => setSelectedDocument(document)}
            selected={document === selectedDocument}
          />;
        })}
      </div>
    </div>
    {!documents[0] &&
      <CustomModal show={showModal} onHide={() => setShowModal(false)}>
        <form id="form-file-upload" onSubmit={(e) => e.preventDefault()}>
          {loading ?
            <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
              <h5 className="mb-5">Subiendo documento...</h5>
              <Loading />
            </div>
            : <>
              <input type="file" id="input-file-upload" multiple={true} onChange={onFileChange} />
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <h5 className="mb-5">Aun no tienes documentos.</h5>
                  <span className="btn btn-primary">Subir archivo</span>
                </div>
              </label>
            </>}
        </form>
      </CustomModal>
    }
  </>);
};

export default FileExplorer;