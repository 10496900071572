import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../app/hooks";
import { LayoutProps } from "./mainLayout";

const SolitaryContentLayout : React.FC<LayoutProps> = (props : LayoutProps) => {

  // If user is logged go to home
  const { isUserLogged } = useUserAuth();
  const navigate = useNavigate();
  if (isUserLogged) navigate('/');

  return (<>
    <div className='d-flex flex-column justify-content-center align-items-center' style={{ minHeight : 'var(--solitary-content-container-height)'}}>
      <img src="img/revilegal_logo.webp" id="logo-solitary"/>
      {props.children}
    </div>
    <span className="d-flex solitary-footer w-100 justify-content-center align-items-center fs-6" style={{ height : 'var(--solitary-footer-height)' }}>
      Proyecto apoyado por:
      <img className="ms-3" src="img/GIJON_impulsa.png" style={{ height : 'var(--solitary-footer-height)' }}/>
      <img className="ms-2" src="img/GIJONTransforma_positivo.png" style={{ height : 'var(--solitary-footer-height)' }}/>
    </span>
  </>);
};

export default SolitaryContentLayout;