// @flow
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
 import eventBus, { BusEventDataDetail, EVENT_BUS_GENERAL_ERROR_UNAUTHORIZED, EVENT_BUS_GENERAL_ERROR_FORBIDDEN } from "./eventBus";
import storage from "./storage";
// import { apiBaseUrl } from "./utils";

//https://altrim.io/posts/axios-http-client-using-typescript

export interface BaseResponse<T = unknown> {
  success : boolean;
  runtime: boolean;
  data : T | null;
  messages?: string[];
}

export interface ResponseError {
  status : number;
  errorMessage : string;
}

enum StatusCode {
    Unauthorized = 401,
    Forbidden = 403,
    TooManyRequests = 429,
    InternalServerError = 500,
}
  
const headers: Readonly<Record<string, string>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "X-Requested-With": "XMLHttpRequest",
};

class Http {
    private instance: AxiosInstance | null = null;
  
    private get http(): AxiosInstance {
      return this.instance != null ? this.instance : this.initHttp();
    }
  
    initHttp() {
      const http = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        // baseURL: apiBaseUrl,
        headers,
        withCredentials: false,
      });
  
      http.interceptors.response.use(
        (response) => response,
        (error) => {
          if (!error.response){
            return this.handleError(error, true);
          } else {
            const { response } = error;
            if ([StatusCode.Unauthorized, StatusCode.Forbidden].includes(response.status))
              return this.handleError(response, false);
            else
              return response;
          }
        }
      );
  
      this.instance = http;
      return http;
    }
  
    request<T = unknown, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
      return this.http.request(config);
    }
  
    get<T = unknown, R = AxiosResponse<T>>(url: string, authHeader? : boolean, config?: AxiosRequestConfig): Promise<R> {
      if (authHeader) config = {
        ...config,
        headers: {
          ...config?.headers,
          'Authorization': 'Bearer '+storage.get('token')
        }
      };
      return this.http.get<T, R>(url, config);
    }
  
    post<T = unknown, R = AxiosResponse<T>>(
      url: string,
      data?: T,
      authHeader?: boolean,
      config?: AxiosRequestConfig): Promise<R> {
      if (authHeader) config = {
        ...config,
        headers: {
          ...config?.headers,
          'Authorization': 'Bearer '+storage.get('token')
        }
      };
      return this.http.post<T, R>(url, data, {...config, headers: {...headers, ...config?.headers}});
    }
  
    put<T = unknown, R = AxiosResponse<T>>(
      url: string,
      data?: T,
      config?: AxiosRequestConfig
    ): Promise<R> {
      return this.http.put<T, R>(url, data, config);
    }
  
    delete<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
      return this.http.delete<T, R>(url, config);
    }
  
    // Handle global app errors
    // We can handle generic app errors depending on the status code
    private handleError(error : AxiosResponse, isAxiosError : boolean) {
      let retval : ResponseError;
     
      const result : BusEventDataDetail = { error: true };

      if (!isAxiosError){
        const { status } = error;
    
        switch (status) {
          case StatusCode.InternalServerError: {
            // Handle InternalServerError
            break;
          }
          case StatusCode.Forbidden: {
            eventBus.dispatch(EVENT_BUS_GENERAL_ERROR_FORBIDDEN, result);
            break;
          }
          case StatusCode.Unauthorized: {
            eventBus.dispatch(EVENT_BUS_GENERAL_ERROR_UNAUTHORIZED, result);
            break;
          }
          case StatusCode.TooManyRequests: {
            // Handle TooManyRequests
            break;
          }
        }
        retval = {
          status : status,
          errorMessage : error.statusText
        };
      } else {   
        const err = (error as unknown) as AxiosError;

        retval = {
          status : 0,
          errorMessage : err.message
        };
      }
  
      return Promise.reject(retval);
    }
  }
  
  export const http = new Http();