import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Input from '../../components/inputs/reusable_input';
import paths from '../../components/router/paths';
import toast from 'react-hot-toast';
import { authRegisterUser } from '../../app/api/auth';
import Loading from '../../components/loading';

interface RegisterFormState {
  email: string;
  password: string;
  name: string;
  lastName: string;
  policy: boolean;
}

const Register: React.FC = () => {
  const [registerFormState, setRegisterFormState] = useState<RegisterFormState>({
    email: '',
    password: '',
    name: '',
    lastName: '',
    policy: false
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setRegisterFormState(prevState => ({
      ...prevState,
      [name]: fieldValue
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      registerFormState.email === '' ||
      registerFormState.password === '' ||
      registerFormState.name === '' ||
      registerFormState.lastName === '' ||
      !registerFormState.policy
    ) return;

    setLoading(true);
    authRegisterUser({
      email: registerFormState.email,
      pass: registerFormState.password,
      name: registerFormState.name,
      last: registerFormState.lastName,
      policy: registerFormState.policy
    }).then(response => {
      if (response.success === false) {
        setLoading(false);
        if (response.messages && response.messages?.length > 0)
          toast(response.messages[0], {icon: '🚫'});
        return;
      }
      navigate(paths.LOGIN);
      toast("Registro completado", {icon: '👍'});
    });
  };

  return (<>
      <h3 className='mb-5'>Crea tu cuenta</h3>
      {loading ? <Loading />
      : <><form onSubmit={handleSubmit} className='main-form'>

          <Input
            label='Email:'
            name="email"
            type="text"
            value={registerFormState.email}
            onChange={handleInputChange}
            required
          />
  

          <Input
            label='Contraseña:'
            name="password"
            type="password"
            value={registerFormState.password}
            onChange={handleInputChange}
            required
          />


          <Input
            label='Nombre:'
            name="name"
            type="text"
            value={registerFormState.name}
            onChange={handleInputChange}
            required
          />



          <Input
            label='Apellidos:'
            name="lastName"
            type="text"
            value={registerFormState.lastName}
            onChange={handleInputChange}
            required
          />
        <div className="d-flex flex-column align-items-center">
          <label>
            <input
              name="policy"
              type="checkbox"
              checked={registerFormState.policy}
              onChange={handleInputChange}
              className='me-2'
              required
            />
            Acepto los términos y condiciones
          </label>

          <button className='btn btn-primary mt-3 mb-3' type="submit">Registrarme</button>
        </div>
        
      </form>
      <p>
        ¿Ya tienes cuenta? <Link to={paths.LOGIN}>Iniciar sesión</Link>.
      </p></>}
  </>);
};

export default Register;
