import { useEffect, useState } from "react";
import FileExplorer from "./file_explorer";
import { CheckClausesResponse, UserDocument, getUserDocuments } from "../../../app/api/documents";
import toast from "react-hot-toast";
import Loading from "../../../components/loading";
import DocumentViewer from "../../../components/documents/document_viewer";
import FileAnalisys from "./file_analisys";
import { distance } from 'fastest-levenshtein';

const Widget: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<UserDocument[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<UserDocument | null>(null);
  const [selectedClause, setSelectedClause] = useState<string>('');
  const [clauses, setClauses] = useState<CheckClausesResponse>({});

  let currentMarkingClause: (string | undefined) = undefined;
  const markClausesOnText = (str: string) => {
    let result = str;
    let startedClause = false;
    for (const c in clauses) {
      if (!clauses[c].exists) continue;
      const startStr = str.substring(0, clauses[c].where.start.length);
      let endStrs = [];
      if (str.includes('. ')) {
        endStrs = str.split('. ');
        endStrs = endStrs.map((endStrOp) => endStrOp.substring(endStrOp.length - clauses[c].where.end.length, endStrOp.length));
      }
      else endStrs = [str.substring(str.length - clauses[c].where.end.length, str.length)];

      if (distance(startStr, clauses[c].where.start) < 5) {
        currentMarkingClause = c;
        result = `<mark title="${clauses[c].name}" data-clause="${c}">${str}</mark>`;
        startedClause = true;
      }
      if (currentMarkingClause === c) {
        for (const i in endStrs) {
          let searchStr = clauses[c].where.end;
          if (endStrs[i].length < clauses[c].where.end.length) searchStr = searchStr.substring(searchStr.length - endStrs[i].length, searchStr.length);
          if (distance(endStrs[i], searchStr) < 5) {
            currentMarkingClause = undefined;
            result = `<mark title="${clauses[c].name}" data-clause="${c}" id="mark-${c}">${str}</mark>`;
            return result;
          }
        }
        if (!startedClause) result = `<mark title="${clauses[c].name}" data-clause="${c}" >${str}</mark>`;
      }
    }
    return result;
  };

  useEffect(() => {
    setLoading(true);
    getUserDocuments().then((response) => {
      if (response.success === false) {
        setLoading(false);
        if (response.messages && response.messages?.length > 0)
          toast(response.messages[0], { icon: '🚫' });
        return;
      }
      if (response.data) {
        setDocuments([...documents, ...response.data.documents.reverse()]);
        setSelectedDocument(response.data.documents[0]);
      }
      setLoading(false);
    });
  }, []);

  const makeMarksClickable = () => {
    const marks = document.querySelectorAll('mark');
    if (marks.length === 0) {
      setTimeout(makeMarksClickable,300);
      return;
    }
    marks.forEach((m) => {
      if (m instanceof HTMLElement) {
        m.addEventListener('click', (e) => {
          const targetElement = (e.target as HTMLElement);
          const clauseName = targetElement.dataset.clause;
          if (clauseName) {
            setSelectedClause(clauseName);
            document.querySelector(`div[data-clause="${clauseName}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
      }
    });
  };

  return (
    <>{loading ? <div className="d-none d-xl-flex"><Loading /></div> :
      <>
        <div className="row h-100 w-100 d-none d-xl-flex">
          {/* FILE EXPLORER */}
          <FileExplorer
            documents={documents}
            setDocuments={setDocuments}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
          />

          {/* PDF VIEWER */}
          <div
            className="col-6 bg-third d-flex justify-content-center"
            style={{ maxHeight: 'calc( 100vh - var(--navbar-height) )', overflowY: 'auto' }}
          >
            {selectedDocument &&
              <div>
                <DocumentViewer
                  document={selectedDocument}
                  customTextRenderer={markClausesOnText}
                  onFinishRender={makeMarksClickable}
                />
              </div>
            }
          </div>

          {/* ANALYSIS OF DOCUMENT */}
          <FileAnalisys
            selectedDocument={selectedDocument}
            selectedClause={selectedClause}
            setSelectedClause={setSelectedClause}
            clauses={clauses}
            setClauses={setClauses}
          />
        </div>
      </>
    }</>
  );
};

export default Widget;