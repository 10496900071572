import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../../components/inputs/reusable_input';
import paths from '../../components/router/paths';
import { LoginUserResponse, authLoginUser } from '../../app/api/auth';
import storage from '../../app/storage';
import Loading from '../../components/loading';
import toast from 'react-hot-toast';

interface LoginFormState {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [formState, setFormState] = useState<LoginFormState>({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (formState.email === '' || formState.password === '') return;
    setLoading(true);
    authLoginUser(
      {
        email: formState.email,
        pass: formState.password
      }
    ).then((response) => {
      if (response.success === false) {
        setLoading(false);
        if (response.messages && response.messages?.length > 0)
          toast(response.messages[0], { icon: '🚫' });
        return;
      }
      const data = response.data as LoginUserResponse;

      // Supuesta Fecha de expiración desde hoy + 7 días
      const currentDate = new Date();
      const expirationDate = new Date(currentDate);
      expirationDate.setDate(currentDate.getDate() + 7);

      // Guardar el token y la fecha de expiración en el storage
      storage.set("token", data.token);
      storage.set("expirationToken", expirationDate.toUTCString());

      navigate("/", { state: { prevUrl: location.pathname } });
    });
  };


  return (<>
    <h3 className='mb-5'>Inicia sesión</h3>
    {loading ? <Loading />
      : <>
        <form onSubmit={handleSubmit} className='main-form'>
          <Input
            label="Email:"
            name='email'
            type="email"
            value={formState.email}
            onChange={handleInputChange}
            autoComplete="username"
            required
          />
          <Input
            label='Contraseña:'
            name="password"
            type="password"
            value={formState.password}
            onChange={handleInputChange}
            autoComplete="current-password"
            required
          />
          <div className="d-flex justify-content-center mt-3 mb-3">
            <button className="btn btn-primary" type="submit">Iniciar sesión</button>
          </div>
        </form>
        <p>
          ¿No tienes una cuenta? <Link to={paths.REGISTER}>Regístrate</Link>
        </p>
        <p>
          <Link to={paths.RECOVER_PASS}>¿Olvidaste tu contraseña?</Link>
        </p>
      </>}
  </>);
};

export default Login;
