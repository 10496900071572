import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

type Props = {
  show: boolean;
  onHide: () => void;
  headerCloseButton?: boolean;
  footerCloseButton?: boolean;
  title?: string;
  children?: React.ReactNode | string;
  footer?: React.ReactNode;
};

const CustomModal: React.FC<Props> = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={props.footerCloseButton || props.headerCloseButton ? true : "static"}
      keyboard={props.footerCloseButton || props.headerCloseButton ? true : false}
    >
      {props.title &&
        <Modal.Header closeButton={props.headerCloseButton}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
      }
      <Modal.Body>
        {props.children}
      </Modal.Body>
      {props.footer || props.footerCloseButton &&
        <Modal.Footer>
          {props.footerCloseButton && <Button onClick={props.onHide}>Cerrar</Button>}
        </Modal.Footer>
      }
    </Modal>
  );
};
export default CustomModal;
