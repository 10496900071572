import { Document, Page } from "react-pdf";
import { UserDocument } from "../../app/api/documents";
import { formatDistance } from "date-fns";
import { es } from 'date-fns/locale';
import { documentsOptions } from "./document_viewer";
import Loading from "../loading";

type Props = {
  document: UserDocument;
  onClick?: () => void;
  selected: boolean;
}

const Thumbnail: React.FC<Props> = ({ document, onClick, selected }: Props) => {
  return (
    <div className={`d-flex m-3 p-3 bg-main rounded border thumbnail-option ${selected ? "selected" : ""} text-wrap overflow-x-hidden`} onClick={onClick}>
      <Document
        file={document.download_url}
        options={documentsOptions}
        className="border"
        loading={
          <div className="d-flex justify-content-center align-items-center" style={{ width: 80, height: 115 }}>
            <Loading />
          </div>
        }
      >
        <Page
          pageNumber={1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={80}
          scale={1}
        />
      </Document>
      <div className="ms-3 d-flex flex-column text-start">
        <span className="fw-bold">{document.name}</span>
        <span className="mt-2">Subido {formatDistance(new Date(document.uploaded), new Date(), { addSuffix: true, locale: es })}.</span>
      </div>
    </div>
  );
};

export default Thumbnail;