import React, { useState } from 'react';
import Input from '../../../components/inputs/reusable_input';
import { Link } from 'react-router-dom';
import paths from '../../../components/router/paths';

interface FormRecoverPassProps {
  onContinue: (email: string) => void;
}

const FormRecoverPass: React.FC<FormRecoverPassProps> = ({ onContinue }) => {
  const [email, setEmail] = useState('');

  const handleContinue = () => {
    if (email.trim() === '') return;
    onContinue(email);
  };

  return (
    <form className='main-form'>
      <h3 className='mb-5 text-center'>Recuperar contraseña</h3>
      <p>Introduce tu correo electrónico y te mandaremos instrucciones para cambiar tu contraseña.</p>
      <div className="mb-3">
        <Input
          label="Email:"
          name="email"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="d-flex justify-content-center">
        <input type="submit" className="btn btn-primary m-2" onClick={handleContinue}  value="Continuar"/>
      </div>
      <p className='text-center mt-3'>
        <Link to={paths.LOGIN}>Volver</Link>
      </p>
    </form>
  );
};

export default FormRecoverPass;
