import { Toaster } from 'react-hot-toast';
import Router from './components/router';

import { pdfjs } from "react-pdf";
//PDF.js CDN need for react-pdf (https://github.com/wojtekmaj/react-pdf#use-external-cdn)
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


function App() {
  return (
    <><Router/><Toaster/></>
  );
}

export default App;
