import React from 'react';
import { Link } from 'react-router-dom';
import paths from '../../../components/router/paths';

interface SendEmailProps {
  email: string;
}

const SendEmail: React.FC<SendEmailProps> = ({ email }) => {
  return (
    <div className="main-form">
      <h3 className='mb-5 text-center'>Verificación de correo electrónico</h3>
      <p>Revisa el correo: <b>{email}</b> y sigue las instrucciones para cambiar tu contraseña.</p>
      <p className='text-center mt-3'>
        <Link to={paths.LOGIN}>Volver</Link>
      </p>
    </div>
  );
};

export default SendEmail;
