import React from 'react';
import AccessWidget from '../dashboard/acces_widget';
import Widget from '../dashboard/widget';
import { useUserAuth } from '../../app/hooks';

const WidgetNoAvailable: React.FC = () => {
  return (
    <div className="d-xl-none d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className='mb-5'>Versión móvil no disponible</h1>
      <h6>Inicia sesión desde un ordenador para disfrutar de la herramienta.</h6>
    </div>
  );
};

const Home: React.FC = () => {

  //Check if user is logged
  const { isUserLogged } = useUserAuth();

  return (isUserLogged ?
    <>
      <Widget />
      <WidgetNoAvailable />
    </>
    : <AccessWidget />
  );
};

export default Home;
