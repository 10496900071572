import React from 'react';
import { Link } from 'react-router-dom';

const AccessWidget = () => {
  return (
    <section className="container d-flex justify-content-center align-items-center">
      <div className="text-center">
        <h3>Bienvenido a Revi.Legal</h3>
        <p>Selecciona para continuar</p>
        <div>
          <Link to="/login" className="btn btn-primary m-2">
            Iniciar sesión
          </Link>
          <Link to="/register" className="btn btn-secondary m-2">
            Registrarse
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AccessWidget;