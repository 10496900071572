import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../app/hooks';
import { authLogoutUser } from '../../app/api/auth';
import toast from 'react-hot-toast';
import Loading from '../../components/loading';
import { useState } from 'react';

interface LogOutProps {
  afterLogout?: () => void;
}

const LogOut: React.FC<LogOutProps> = ({ afterLogout }: LogOutProps) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { logoutUser } = useUserAuth();
  const handleLogout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setLoading(true);
    authLogoutUser().then((response) => {
      if (response.success === false) {
        if (response.messages && response.messages?.length > 0)
          toast(response.messages[0], { icon: '🚫' });
      }
      else toast('Hasta la próxima', { icon: '👏' });
    }).then(() => {
      logoutUser();
      afterLogout && afterLogout();
      setLoading(false);
      navigate('/');
    });
  };

  return (<>{
    loading ?
      <Loading className='ms-5'/> :
      <button onClick={handleLogout} className="btn btn-secondary m-2 ms-5">
        Cerrar sesión
      </button>
  }</>);
};

export default LogOut;
