import LogOut from "../../pages/login/logout";
import { useUserAuth } from "../../app/hooks";
import { useLocation } from "react-router-dom";
import Loading from "../loading";

export type LayoutProps = { children : JSX.Element | JSX.Element[] }

const MainLayout : React.FC<LayoutProps>= (props : LayoutProps) => {

//Check if client comes from login
let comesFromLogin = false;
const location = useLocation();
if (location.state && location.state.prevUrl && location.state.prevUrl === '/login')
  comesFromLogin = true;

//Check if user is logged
const { isUserLogged, loading } = useUserAuth(!comesFromLogin);

  return (<>
    <nav className="navbar d-flex">
        <div>
          <a className="navbar-brand fs-3 fw-light" href="/"><img src="img/revilegal_logo.webp" id="logo"/></a>
        </div>
        <div className="d-flex align-items-center">
          <span className="d-flex solitary-footer justify-content-center align-items-center fs-6" style={{ height : 'var(--sponsors-height)' }}>
            Proyecto apoyado por:
            <img className="ms-3" src="img/GIJON_impulsa.png" style={{ height : 'var(--sponsors-height)' }}/>
            <img className="ms-2" src="img/GIJONTransforma_positivo.png" style={{ height : 'var(--sponsors-height)' }}/>
          </span>
          {isUserLogged && <LogOut/>} 
        </div>  
      </nav>
    <div className='container-fluid main-box m-0 p-0'>
      {loading ? <Loading /> : props.children}
    </div>
  </>);
};

export default MainLayout;