// @flow

const paths = {
    ROOT: "/",
    LOGIN: "/login",
    LOGIN_NAME: "/login/identifier",
    LOGIN_PASS: "/login/password",
    REGISTER: "/register",
    RECOVER_PASS: "/recover-password",
    RESET_PASS: "/reset-password",

     //privates
    DASHBOARD: "/dashboard",
    DASHBOARD_LOGOUT: "/logout",
    DASHBOARD_REFRESH_TOKEN: "/refresh-token",
    DASHBOARD_CHANGE_PASSWORD: "/change-password",
    DASHBOARD_USER_DOCUMENTS: "/user-documents"
};

export const getBreadcrumbName = (path: string): string => {
    const segments = path.split("/");
    const segment = segments[segments.length - 1];
    // const presegment = segments.length > 1 ? segments[segments.length - 2] : null;

    let found = false;

    if (paths.ROOT.indexOf(segment) !== -1) found = true; 
      if (found) return "Inicio";



    if (paths.LOGIN.indexOf(segment) !== -1) found = true;
      if (found) return "Inicio de sesión";

      if (paths.LOGIN_NAME.indexOf(segment) !== -1) found = true;
      if (found) return "Registro de usuario";

      if (paths.LOGIN_PASS.indexOf(segment) !== -1) found = true;
      if (found) return "Registro de usuario";

      if (paths.REGISTER.indexOf(segment) !== -1) found = true;
      if (found) return "Registro de usuario";

      if (paths.RECOVER_PASS.indexOf(segment) !== -1) found = true;
      if (found) return "Recuperar contraseña";


      if (paths.RESET_PASS.indexOf(segment) !== -1) found = true;
      if (found) return "Restablecer contraseña";


      
      // if (paths.REFRESH_TOKEN.indexOf(segment) !== -1) {
      //   return "Actualizar token";
      // }
    
      // if (paths.CHANGE_PASSWORD.indexOf(segment) !== -1) {
      //   return "Cambiar contraseña";
      // }
    
      // if (paths.USER_DOCUMENTS.indexOf(segment) !== -1) {
      //   return "Documentos de usuario";
      // }

      

    return "Not found!!!";
};

export default paths;