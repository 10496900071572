import React, { useState } from 'react';
import Input from '../../components/inputs/reusable_input';
import { authChangePassword } from '../../app/api/auth';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading';

interface ChangePassFormState {
  email: string;
  currentPass: string;
  newPass: string;
  repeatNewPass: string;
}

const ChangePass: React.FC = () => {
  const [formState, setFormState] = useState<ChangePassFormState>({
    email: '',
    currentPass: '',
    newPass: '',
    repeatNewPass: '',
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      formState.email === '' ||
      formState.currentPass === '' ||
      formState.newPass === '' ||
      formState.repeatNewPass === ''
    ) return;
    if (formState.newPass !== formState.repeatNewPass) {
      toast("Las contraseñas no coinciden", { icon: '🚫' });
      return;
    }
    setLoading(true);
    authChangePassword({
      email: formState.email,
      "current_pass": formState.currentPass,
      "new_pass": formState.newPass,
      "repeat_new_pass": formState.repeatNewPass
    }).then((response) => {
      if (response.success === false) {
        setLoading(false);
        if (response.messages && response.messages?.length > 0)
          toast(response.messages[0], { icon: '🚫' });
        return;
      }
      setLoading(false);
      navigate('/');
      toast("Contraseña cambiada con éxito", { icon: '👍' });
    });
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <h3 className='mb-5'>Cambiar contraseña</h3>
      {loading ? <Loading /> :
        <form onSubmit={handleSubmit} className='main-form'>
          <div>
            <Input
              label="Email:"
              type="email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>

            <Input
              label="Contraseña actual:"
              type="password"
              name="currentPass"
              value={formState.currentPass}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>

            <Input
              label="Nueva contraseña:"
              type="password"
              name="newPass"
              value={formState.newPass}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>

            <Input
              label='Repite la contraseña:'
              type="password"
              name="repeatNewPass"
              value={formState.repeatNewPass}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn-primary m-2" type="submit">Change Password</button>
          </div>
        </form>
      }</div>
  );
};

export default ChangePass;
