import { useEffect, useState } from "react";
import storage from "./storage";
import eventBus, { EVENT_BUS_AUTH_USER_LOGOUT, EVENT_BUS_GENERAL_ERROR_UNAUTHORIZED } from "./eventBus";
import { authRefreshToken } from "./api/auth";
import toast from "react-hot-toast";

export const useUserAuth = (refreshToken?: boolean) => {
  const [isUserLogged, setIsLogged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const token = storage.get("token");
    const expirationToken = storage.get('expirationToken');
    if (
      token &&
      expirationToken &&
      new Date(expirationToken) > new Date() // && Check JWT veracity...
    ) {
      if (refreshToken) {
        setLoading(true);
        authRefreshToken().then(response => {
          if (response.success && response.data) {
            storage.set("token", response.data.token);
            storage.set("expirationToken", response.data.expires);
            setIsLogged(true);
          } else {
            clear();
            response.messages && toast(response.messages[0], { icon: '🚫' });
          }
          setLoading(false);
        });
      } else setIsLogged(true);
      const unSuscribeClear = eventBus.on(EVENT_BUS_AUTH_USER_LOGOUT, clear);
      const unSuscribeUnauthorized = eventBus.on(EVENT_BUS_GENERAL_ERROR_UNAUTHORIZED, clear);
      return () => {
        unSuscribeClear();
        unSuscribeUnauthorized();
      };
    }
    clear();
  }, []);

  const clear = (): void => {
    storage.clear();
    setIsLogged(false);
  };

  const logoutUser = (): void => {
    eventBus.dispatch(EVENT_BUS_AUTH_USER_LOGOUT);
  };

  return { isUserLogged, logoutUser, loading };
};