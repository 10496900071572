import { useEffect, useState } from "react";
import { CheckClausesResponse, GetClauseInformationResponse, UserDocument, checkClauses, getClauseInformation } from "../../../app/api/documents";
import toast from "react-hot-toast";
import Loading from "../../../components/loading";

type Props = {
  selectedDocument: UserDocument | null;
  selectedClause: string;
  setSelectedClause: (clause: string) => void;
  clauses: CheckClausesResponse;
  setClauses: (clauses: CheckClausesResponse) => void;
}


const FileAnalisys: React.FC<Props> = ({ selectedDocument, clauses, selectedClause, setClauses, setSelectedClause }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingClausesInfo, setLoadingClausesInfo] = useState<boolean>(false);
  const [clausesInfo, setClausesInfo] = useState<GetClauseInformationResponse>([]);

  useEffect(() => {
    setClauses({});
    setClausesInfo([]);
    if (selectedDocument && selectedDocument.uid) {
      setLoading(true);
      checkClauses(selectedDocument.uid).then((response) => {
        if (response.success === false) {
          setLoading(false);
          if (response.messages && response.messages?.length > 0)
            toast(response.messages[0], { icon: '🚫' });
          return;
        }
        if (response.data) setClauses(response.data);
        setLoading(false);
      });
    }
  }, [selectedDocument]);

  useEffect(() => {
    setClausesInfo([]);
    if (selectedDocument && selectedDocument.uid && selectedClause !== '') {
      setLoadingClausesInfo(true);
      getClauseInformation(selectedDocument?.uid, selectedClause).then((response) => {
        if (response.success === false) {
          setLoadingClausesInfo(false);
          if (response.messages && response.messages?.length > 0)
            toast(response.messages[0], { icon: '🚫' });
          return;
        }
        if (response.data) setClausesInfo(response.data);
        setLoadingClausesInfo(false);
      });
    }
  }, [selectedClause]);

  return (
    <div className="col-3 bg-second m-0 p-0">
      <div className="bg-main d-flex align-items-center border-bottom justify-content-between p-4" style={{ height: "var(--navbar-height)" }}>
        <h5>Análisis del documento:</h5>
        {loading && <Loading width={38} />}
      </div>
      <div className="m-0 p-0 pb-3 border" style={{ height: 'var(--clausesTitleContainer-height)', overflowY: 'auto' }}>
        {Object.keys(clauses).map((key, index) => {
          const onClick = () => { 
            setSelectedClause(key); 
            document.querySelector(`#mark-${key}`)?.scrollIntoView({ behavior: 'smooth' , block: 'center'});
          };
          return (
            <div key={index}
              className={`m-2 p-4 bg-main rounded border clauses-option ${selectedClause === key ? "selected" : ""} ${clauses[key].exists ? "" : "disabled"}`}
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={clauses[key].exists ? onClick : undefined }
              data-clause={key}
            >
              <span>{clauses[key].name}</span>
              <span className="ms-4">{clauses[key].exists ? '✔️' : '✖️' }</span>
            </div>
          );
        })}
      </div>
      <div className="m-0 p-0 pb-3 border" style={{ height: 'var(--clausesInfoContainer-height)', overflowY: 'auto' }}>
        {loadingClausesInfo && <div className="d-flex justify-content-center align-items-center h-100"><Loading width={38} /></div>}
        {clausesInfo.map((clause, index) => {
          return (
            <div key={index} className="m-2 p-4 bg-main rounded border">
              <h5>{clause.question}</h5>
              <p>{clause.explanation}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FileAnalisys;