import { Route, BrowserRouter, Routes } from "react-router-dom";

import paths from "./paths";
import Home from "../../pages/home";
import Login from "../../pages/login/login";
import RecoverPassword from "../../pages/login/recoverPassword/recover_password";
import ResetPassword from "../../pages/login/recoverPassword/reset_password";
import NotFound from "../../pages/notfound";
import RegisterIdentification from "../../pages/register/register";
import SolitaryContentLayout from "../layouts/solitaryContent";
import MainLayout from "../layouts/mainLayout";
import ChangePass from "../../pages/dashboard/change_pass";

const RouterComponent = (): React.ReactElement | null => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout><Home /></MainLayout>} />
        <Route path={paths.DASHBOARD_CHANGE_PASSWORD} element={<MainLayout><ChangePass /></MainLayout>} />

        <Route path={paths.LOGIN} element={<SolitaryContentLayout><Login /></SolitaryContentLayout>} />
        <Route path={paths.REGISTER} element={<SolitaryContentLayout><RegisterIdentification /></SolitaryContentLayout>} />
        <Route path={paths.RECOVER_PASS} element={<SolitaryContentLayout><RecoverPassword /></SolitaryContentLayout>} />
        <Route path={paths.RESET_PASS} element={<SolitaryContentLayout><ResetPassword /></SolitaryContentLayout>} />

        <Route path="*" element={<MainLayout><NotFound /></MainLayout>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
