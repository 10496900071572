// import { useLocation } from 'react-router-dom';
// import { useAppSelector } from '../../app/hooks';
// import path from '../../../components/Router/paths';
// import { selectIsLogged } from '../../app/reducers/authSlice';

// const NotFound = () : JSX.Element => {
//     const location = useLocation();
//     const isAuth = useAppSelector(selectIsLogged);
  
//     const userPath = isAuth ? path.ROOT : path.LOGIN;
// return( <h1>Not found</h1>);
// };

// export default NotFound;



const NotFound = () : JSX.Element => {
    
return( <h1>Not found</h1>);
};

export default NotFound;