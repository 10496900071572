import React, { useState } from 'react';
import SendEmail from './send_email';
import FormRecoverPass from './form_recover_pass';
import { authRecoverPassword } from '../../../app/api/auth';
import Loading from '../../../components/loading';
import toast from 'react-hot-toast';

const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [showSendEmail, setShowSendEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleContinue = (userEmail: string) => {
    setLoading(true);
    setEmail(userEmail);
    authRecoverPassword({email : userEmail}).then(response => {
      if (response.success === false) {
        setLoading(false);
        if (response.messages && response.messages?.length > 0)
          toast(response.messages[0], {icon: '🚫'});
        return;
      }
      setShowSendEmail(true);
      setLoading(false);
    });
  };

  if (loading) 
    return (
      <>
      <h3 className='mb-5'>Recuperar contraseña</h3>
        <Loading/>
      </>
    );
  else return (
    <>
    {showSendEmail ? 
        <SendEmail email={email} />
        : 
        <FormRecoverPass onContinue={handleContinue} />
      }
    </>
  );
};

export default RecoverPassword;
