import React from 'react';

interface InputProps {
  type: string;
  name: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  checked?: boolean;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  label?: string; 
  focusOnMount?: boolean;
  autoComplete?: string;
}

const Input: React.FC<InputProps> = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  required,
  id,
  disabled,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checked,
  label,
  focusOnMount,
  autoComplete
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <div className='mb-3'>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type={type}
        name={name}
        className="form-control custom-input"
        id={id}
        value={value}
        onChange={handleChange} 
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        autoFocus={focusOnMount}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default Input;
