import { useEffect, useState } from "react";
import { UserDocument } from "../../app/api/documents";
import { pdfjs, Document, Page } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Loading from "../loading";

type Props = {
  document: UserDocument;
  customTextRenderer?: (str: string, index: number) => string;
  onFinishRender?: () => void;
}

export const documentsOptions = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`
};

const DocumentViewer: React.FC<Props> = ({ document, customTextRenderer, onFinishRender }: Props) => {
  const [numPages, setNumPages] = useState<number>();
  const [documentScale, setDocumentScale] = useState<number>(1);

  useEffect(() => {
    getDocumentScale();
    return window.addEventListener("resize", getDocumentScale);
  }, []);

  useEffect(() => {
    if (onFinishRender) setTimeout(onFinishRender, 500);
  }, [customTextRenderer]);

  function getDocumentScale() {
    const userViewSize = window.innerWidth;
    // Small laptop
    if (userViewSize <  1520 ) return setDocumentScale(1);
    // Medium laptop
    else if (userViewSize < 1710 ) return setDocumentScale(1.1);
    // HD screen
    else if (userViewSize < 2140 ) return setDocumentScale(1.25);
    // Large screen
    else return setDocumentScale(1.75);
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy) {
    setNumPages(nextNumPages);
  }

  return (
    <Document
      file={document.download_url}
      onLoadSuccess={onDocumentLoadSuccess}
      options={documentsOptions}
      loading={
        <div className="d-flex justify-content-center align-items-center" style={{ height: "calc(100vh - var(--navbar-height))" }}>
          <Loading />
        </div>
      }
      
    >
      {Array.from(new Array(numPages), (el, index) => {
        return (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={documentScale}
            renderTextLayer={true}
            renderAnnotationLayer={false}
            loading={""}
            customTextRenderer={({ str, itemIndex }) => {
              if (customTextRenderer) return customTextRenderer(str, itemIndex);
              else return str;
            }}
          />
        );
      })}
    </Document>
  );
};

export default DocumentViewer;