import { BaseResponse, http } from "../http";

// Public
const API_ENDPOINT_LOGIN_USER = '/api/public/login';
const API_ENDPOINT_REGISTER_USER = '/api/public/register';
const API_ENDPOINT_RECOVER_PASSWORD = '/api/public/recover-password';
const API_ENDPOINT_RESET_PASSWORD = '/api/public/reset-password';
// Private
const API_ENDPOINT_LOGOUT_USER = '/api/private/logout';
const API_ENDPOINT_CHANGE_PASSWORD = '/api/private/change-password';
const API_ENDPOINT_REFRESH_TOKEN = '/api/private/refresh-token';


export type RefreshTokenResponse = {
    token: string;
    expires: string;
}

export const authRefreshToken = async (): Promise<BaseResponse<RefreshTokenResponse>> => {

    const result = await http.get<BaseResponse<RefreshTokenResponse>>(API_ENDPOINT_REFRESH_TOKEN, true)
        .then(data => {
            return data.data;
        })
        .catch(() => {
            return {
                success: false,
                runtime: true,
                messages: [`Ha expirado tu sesión`]
            } as BaseResponse<RefreshTokenResponse>;
        });


    return result;
};

export type LoginUserRequestBody = {
    email: string;
    pass: string;
};

export type LoginUserResponse = {
    token: string;
    expires: string;
    roles: string[];
    name: string;
};

export const authLoginUser = async (datareq: LoginUserRequestBody): Promise<BaseResponse<LoginUserResponse>> => {

    const result = await http.post<LoginUserRequestBody, BaseResponse<LoginUserResponse>>(API_ENDPOINT_LOGIN_USER, datareq)
        .then(data => {
            return data.data as unknown as BaseResponse<LoginUserResponse>;
        })
        .catch(error => {
            return {
                success: false,
                runtime: true,
                messages: [`Error login user: [Status: ${error.status}] - [Msg: ${error.errorMessage}]`]
            } as BaseResponse<LoginUserResponse>;
        });


    return result;
};

export type LogoutUserResponse = {
    logout: boolean;
};

export const authLogoutUser = async (): Promise<BaseResponse<LogoutUserResponse>> => {
    const result = await http.get<BaseResponse<LogoutUserResponse>>(API_ENDPOINT_LOGOUT_USER, true)
        .then(data => {
            return data.data as unknown as BaseResponse<LogoutUserResponse>;
        })
        .catch(error => {
            return {
                success: false,
                runtime: true,
                messages: [`Error logout user: [Status: ${error.status}] - [Msg: ${error.errorMessage}]`]
            } as BaseResponse<LogoutUserResponse>;
        });
    return result;
};


export type RegisterUserRequestBody = {
    email: string;
    pass: string;
    name: string;
    last: string;
    policy: boolean;
};

export type RegisterUserResponse = {
    registered: boolean;
};

export const authRegisterUser = async (datareq: RegisterUserRequestBody): Promise<BaseResponse<RegisterUserResponse>> => {

    const result = await http.post<RegisterUserRequestBody, BaseResponse<RegisterUserResponse>>(API_ENDPOINT_REGISTER_USER, datareq)
        .then(data => {
            return data.data as unknown as BaseResponse<RegisterUserResponse>;
        })
        .catch(error => {
            return {
                success: false,
                runtime: true,
                messages: [`Error registering user: [Status: ${error.status}] - [Msg: ${error.errorMessage}]`]
            } as BaseResponse<RegisterUserResponse>;
        });


    return result;
};

export type RecoverPasswordRequestBody = {
    email: string;
};

export type RecoverPasswordResponse = {
    sent: boolean;
};

export const authRecoverPassword = async (datareq: RecoverPasswordRequestBody): Promise<BaseResponse<RecoverPasswordResponse>> => {

    const result = await http.post<RecoverPasswordRequestBody, BaseResponse<RecoverPasswordResponse>>(API_ENDPOINT_RECOVER_PASSWORD, datareq)
        .then(data => {
            return data.data as unknown as BaseResponse<RecoverPasswordResponse>;
        })
        .catch(error => {
            return {
                success: false,
                runtime: true,
                messages: [`Error recovering password: [Status: ${error.status}] - [Msg: ${error.errorMessage}]`]
            } as BaseResponse<RecoverPasswordResponse>;
        });


    return result;
};

export type ChangePasswordRequestBody = {
    email: string;
    "current_pass": string;
    "new_pass": string;
    "repeat_new_pass": string;
};

export type ChangePasswordResponse = {
    changed: boolean;
};

export const authChangePassword = async (datareq: ChangePasswordRequestBody): Promise<BaseResponse<ChangePasswordResponse>> => {

    const result = await http.post<ChangePasswordRequestBody, BaseResponse<ChangePasswordResponse>>(API_ENDPOINT_CHANGE_PASSWORD, datareq, true)
        .then(data => {
            return data.data as unknown as BaseResponse<ChangePasswordResponse>;
        })
        .catch(error => {
            return {
                success: false,
                runtime: true,
                messages: [`Error changing password: [Status: ${error.status}] - [Msg: ${error.errorMessage}]`]
            } as BaseResponse<ChangePasswordResponse>;
        });


    return result;
};

export type ResetPasswordRequestBody = {
    email: string;
    uuid: string;
    password: string;
    "repeat_password": string;
};

export type ResetPasswordResponse = {
    reset: boolean;
};

export const authResetPassword = async (datareq: ResetPasswordRequestBody): Promise<BaseResponse<ResetPasswordResponse>> => {

    const result = await http.post<ResetPasswordRequestBody, BaseResponse<ResetPasswordResponse>>(API_ENDPOINT_RESET_PASSWORD, datareq)
        .then(data => {
            return data.data as unknown as BaseResponse<ResetPasswordResponse>;
        })
        .catch(error => {
            return {
                success: false,
                runtime: true,
                messages: [`Error reseting password: [Status: ${error.status}] - [Msg: ${error.errorMessage}]`]
            } as BaseResponse<ResetPasswordResponse>;
        });


    return result;
};