import { BaseResponse, http } from "../http";

const API_ENDPOINT_GET_DOCUMENTS = '/api/private/user-documents';
const API_ENDPOINT_UPLOAD_DOCUMENT = '/api/private/upload-document';
const API_ENDPOINT_CLAUSES = '/api/private/gpt-clauses';

export type UserDocument = {
  uid: string;
  name: string;
  size: number;
  uploaded: string;
  download_url: string;
}

export const uploadDocument = async (datareq: FormData): Promise<BaseResponse<UserDocument>> => {
  const result = await http.post<FormData, BaseResponse<UserDocument>>(API_ENDPOINT_UPLOAD_DOCUMENT, datareq, true,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(data => {
      return data.data as unknown as BaseResponse<UserDocument>;
    })
    .catch(() => {
      return {
        success: false,
        runtime: true,
        messages: [`Error subiendo archivo`]
      } as BaseResponse<UserDocument>;
    });
  return result;
};

type GetDocumentsResponse = {
  documents: UserDocument[];
};

export const getUserDocuments = async (): Promise<BaseResponse<GetDocumentsResponse>> => {
  const result = await http.get<BaseResponse<GetDocumentsResponse>>(API_ENDPOINT_GET_DOCUMENTS, true)
    .then(data => {
      return data.data;
    })
    .catch(() => {
      return {
        success: false,
        runtime: true,
        messages: [`Error obteniendo documentos`]
      } as BaseResponse<GetDocumentsResponse>;
    });
  return result;
};

type Clause = {
  exists : boolean;
  name: string;
  where : {
    start : string;
    end : string;
  }
};
// Objeto con keys de tipo string y valores de tipo Clause
export type CheckClausesResponse = Record<string, Clause>;

export const checkClauses = async (uid: string): Promise<BaseResponse<CheckClausesResponse>> => {
  const result = await http.get<BaseResponse<CheckClausesResponse>>(API_ENDPOINT_CLAUSES+"/"+uid, true)
    .then(data => {
      return data.data;
    })
    .catch(() => {
      return {
        success: false,
        runtime: true,
        messages: [`Error obteniendo información del análisis`]
      } as BaseResponse<CheckClausesResponse>;
    });
  return result;
};
type ClauseInformation = {
  question : string;
  explanation : string;
}
export type GetClauseInformationResponse = ClauseInformation[];

export const getClauseInformation = async (uid: string, clauseKey : string): Promise<BaseResponse<GetClauseInformationResponse>> => {
  const result = await http.get<BaseResponse<GetClauseInformationResponse>>(API_ENDPOINT_CLAUSES+"/"+uid+"/"+clauseKey, true)
    .then(data => {
      return data.data;
    })
    .catch(() => {
      return {
        success: false,
        runtime: true,
        messages: [`Error obteniendo información de la claúsula`]
      } as BaseResponse<GetClauseInformationResponse>;
    });
  return result;
};