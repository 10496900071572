import React from 'react';

type Props = {
  width?: number;
  className?: string;
};

const Loading: React.FC<Props> = (props : Props) => {
  return (
    <div className={`d-flex align-items-center justify-content-center ${props.className ? props.className : ''}`} style={{ width : props.width??'auto'}}>
      <div className="spinner-border" role="status">
      </div>
    </div>
  );
};

export default Loading;
